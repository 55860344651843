<template>
    <div :class="isRealmREorSA ? 'master-black' : 'master-white'">
        <!--<div class="col-lg-3 col-sm-1 col-md-2 col-xs-1 col-1"></div>-->
        <div>
            <div class="mainContentSinglePage" :style="{backgroundImage : personalCandidate.CssMedia.BackgroundColorCss}">
                <!--:style="{backgroundImage : personalCandidate.CssMedia.BackgroundColorCss}"-->
                <!-- Best Managed Image -->
               <img :class="[
                                'img-fluid',
                                brand === 'Leaps by HRnet' ? 'companyLogo-topRight-leaps':
                                realm === 'vent' ? 'companyLogo-topRight-vent' :
                                realm === 'res' ? 'companyLogo-topRight-res' : 'companyLogo-topRight'
                            ]"
                     alt="Best Managed"
                    :src="(realm === 'res' || realm === 'sa' || realm === 'rel') 
                            ? require('@/assets/img_singlepageprofile/best_managed_sg_color.png') 
                            : require('@/assets/img_singlepageprofile/best_managed_sg_full_white.png')"
                     onerror="this.style.display='none'" />
                <div class="masterContainerWrapper">
                    <br />
                    <div class="companyLogoSection row">
                        <!--<div class="col-lg-4 col-md-4 col-sm-4 col-2"></div>-->
                        <!--<div class="companyLogoSection col-lg-4 col-md-4 col-sm-4 col-8">-->
                        <div class="col-lg-4 col-md-4 col-sm-4 col-8">
                            <picture v-if="personalCandidate != null && personalCandidate != undefined && personalCandidate.EmployeeInformation != undefined">
                                <!-- Company Logo -->
                                <img :class="['img-fluid', this.brand === 'RecruitExpress' ? 'companyLogo-vertical' : 'companyLogo']"
                                     alt="CompanyCaption"
                                     :src="'.' + require('@/assets/img_singlepageprofile/' + personalCandidate.CssMedia.BackgroundCompanyLogo)"
                                     onerror="this.style.display='none'" />
                                <!-- mobile version align with logo-->
                                <!--<img :class="[
                                      'img-fluid',
                                      realm === 'vent' ? 'companyLogo-topRight-vent' :
                                      realm === 'res' ? 'companyLogo-topRight-res' : 'companyLogo-topRight'
                                  ]"
                                                         alt="Best Managed"
                                                         :src="(realm === 'res' || realm === 'sa' || realm === 'rel')
                                  ? require('@/assets/img_singlepageprofile/best_managed_sg_color.png')
                                  : require('@/assets/img_singlepageprofile/best_managed_sg_full_white.png')"
                                                         onerror="this.style.display='none'" />-->
                            </picture>
                        </div>
                    </div>
                    <br />
                    <div class="PhotoSection row" v-if=" personalCandidate.EmployeeInformation != null">
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2"></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-8">
                            <!-- With A link-->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="personalCandidate.EmployeeInformation.selfIntroVideoUrl.length >0">
                                <div class="card-img" style="position:relative; float:left">
                                    <img class="img-fluid profile-picture" style="position:relative; top:0; left:0;" alt="ProfilePicture" :src="personalCandidate.imageConsultant" v-if="personalCandidate.imageConsultant.length > 0" />
                                </div>
                            </div>
                            <!-- Without A Link-->
                            <div class="col-lg-12 col-sm-12 col-md-12 col-12" v-if="personalCandidate.EmployeeInformation.selfIntroVideoUrl.length <= 0">
                                <div class="card-img">
                                    <img class="img-fluid profile-picture" alt="ProfilePicture" :src="personalCandidate.imageConsultant" v-if="personalCandidate.imageConsultant.length >0" onerror="this.style.display='none'" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2"></div>
                    </div>
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 NamingFont NameSection" v-if="personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation != undefined">
                        <div class="recruitmentName">{{personalCandidate.EmployeeInformation.displayName}}</div>
                        <div>
                            <div class="RecruitmentRole">{{personalCandidate.EmployeeInformation.jobTitle}}</div>
                        </div>
                        <br />

                        <div class="containerWrapper">

                            <!--Video-->
                            <div class="commonContainer" v-if="personalCandidate.EmployeeInformation.selfIntroVideoUrl.length >0">
                                <div :class="['intoVideoSection', {'intoVideoSection-black': isRealmREorSA}]">
                                    <a :class="{'clickToWatchIntroVideo': !isRealmREorSA, 'clickToWatchIntroVideo-black': isRealmREorSA}" target="_blank" :href="personalCandidate.EmployeeInformation.selfIntroVideoUrl">
                                        <div>
                                            <img class="img-fluid iconImgPlay" alt="rounded" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/black_Play_Button.png') : require('@/assets/img_singlepageprofile/white_Play_Button.png')" />
                                            Watch {{personalCandidate.EmployeeInformation.firstName}}'s Intro Video
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <!-- Display NPS section -->
                            <div v-if="personalCandidate.EmployeeInformation.npS_Weekly !== null && personalCandidate.EmployeeInformation.npS_Weekly >= 80 && personalCandidate.EmployeeInformation.totalResponses >= 1" class="commonContainer">
                                <div v-if="personalCandidate.EmployeeInformation.totalResponses !== null">
                                    <div :class="['npsScore', {'npsScore-black': isRealmREorSA}]">
                                        <!-- Display different NPS score message for user's role above Team Leader -->
                                        <div v-if="personalCandidate.EmployeeInformation.designationType < 4">
                                            <div class="text-center">
                                                <img class="img-fluid iconImgStar" alt="rounded" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/Black_Star.png') : require('@/assets/img_singlepageprofile/White_Star.png')" />
                                                <span class="npsScoreStarText">{{ Math.floor(personalCandidate.EmployeeInformation.npS_Weekly) }} </span>
                                                <span class="npsScoreStarLabel"> NPS</span>

                                            </div>
                                            <span class="npsScoreDescription">
                                                {{ personalCandidate.EmployeeInformation.firstName }} and the team have a combined NPS of {{ Math.floor(personalCandidate.EmployeeInformation.npS_Weekly) }}. You’re working with one of our best!
                                            </span>
                                        </div>
                                        <div v-else>
                                            <div class="text-center">
                                                <img class="img-fluid iconImgStar" alt="rounded" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/White_Star.png')" />
                                                <span class="npsScoreStarText">{{ Math.floor(personalCandidate.EmployeeInformation.npS_Weekly) }} </span>
                                                <span class="npsScoreStarLabel">NPS</span>
                                            </div>
                                            <span class="npsScoreDescription">
                                                {{personalCandidate.EmployeeInformation.firstName}} has an NPS of {{ Math.floor(personalCandidate.EmployeeInformation.npS_Weekly) }}. You’re working with one of our best!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="commonContainer">
                                <div :class="['GetInTouchSection', {'GetInTouchSection-black': isRealmREorSA}]">
                                    <h4 class="getInTouchTitle">Get In Touch</h4>
                                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 getInTouchIcon" v-if="personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation != undefined && personalCandidate.EmployeeInformation.mobileNo.length >0">
                                        <a :class="{'getInTouchText': !isRealmREorSA, 'getInTouchText-black': isRealmREorSA, 'RecruitmentContact': true}">
                                            <span>
                                                <img class="img-fluid iconImg" alt="rounded" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/location-icon-black.png') : require('@/assets/img_singlepageprofile/location-icon.png')" />
                                            </span>
                                            <span style="margin-left:3.0em;">
                                                <label class="textline">{{personalCandidate.EmployeeInformation.cityName}}</label>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 getInTouchIcon" v-if="personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation != undefined && personalCandidate.EmployeeInformation.mobileNo.length >0">
                                        <a :class="{'getInTouchText': !isRealmREorSA, 'getInTouchText-black': isRealmREorSA, 'RecruitmentContact': true}" :href="'tel:'+personalCandidate.EmployeeInformation.mobileNo">
                                            <span>
                                                <img class="img-fluid iconImg" alt="rounded" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/phoneiconcontact-black.png') : require('@/assets/img_singlepageprofile/phoneiconcontact.png')" />
                                            </span>
                                            <span style="margin-left: 3.0em;">
                                                <label class="textline"> + {{personalCandidate.EmployeeInformation.mobileNo}}</label>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 getInTouchIcon" v-if="personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation != undefined && personalCandidate.EmployeeInformation.emailAddress.length >0">
                                        <a :class="{'getInTouchText': !isRealmREorSA, 'getInTouchText-black': isRealmREorSA, 'RecruitmentContact': true}" :href="'mailto:'+personalCandidate.EmployeeInformation.emailAddress">
                                            <span>
                                                <img class="img-fluid iconImg" alt="rounded" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/mail-icon-black.png') : require('@/assets/img_singlepageprofile/mail-icon.png')" />
                                            </span>
                                            <span style="margin-left: 3.0em; margin-top: 10px; ">
                                                <label class="textline">{{personalCandidate.EmployeeInformation.emailAddress}}</label>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 ContactDetails getInTouchIcon" style="margin-top: 2px;" v-if="personalCandidate.URLs.employeeWhatsappUrl.length >0">
                                        <a :class="{'getInTouchText': !isRealmREorSA, 'getInTouchText-black': isRealmREorSA, 'RecruitmentContact': true}" target="_blank" :href="personalCandidate.URLs.employeeWhatsappUrl" v-if="personalCandidate.URLs.employeeWhatsappUrl.length >0">
                                            <span>
                                                <img class="img-fluid iconImg" alt="rounded" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/phone-call-black.png') : require('@/assets/img_singlepageprofile/phone-call.png')" onerror="this.style.display='none'" />
                                            </span>
                                            <span style="margin-left: 3.0em;">
                                                <label class="textline"> {{personalCandidate.URLs.employeeWhatsappUrl}}</label>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 ContactDetails getInTouchIcon" style="margin-top: 3px;" v-if="personalCandidate.URLs.employeeLineUrl.length > 0">
                                        <a :class="{'getInTouchText': !isRealmREorSA, 'getInTouchText-black': isRealmREorSA, 'RecruitmentContact': true}" target="_blank" :href="personalCandidate.URLs.employeeLineUrl" v-if="personalCandidate.URLs.employeeLineUrl.length >0">
                                            <span>
                                                <img class="img-fluid iconImg" alt="rounded" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/line_icon-black.png') : require('@/assets/img_singlepageprofile/line_icon.png')" onerror="this.style.display='none'" />
                                            </span>
                                            <span style="margin-left: 3.0em;">
                                                <label class="textline">  {{personalCandidate.URLs.employeeLineUrl}}</label>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 ContactDetails getInTouchIcon" style="margin-top: 3px;" v-if=" personalCandidate.URLs.employeeLinkedInUrl.length >0">
                                        <a :class="{'getInTouchText': !isRealmREorSA, 'getInTouchText-black': isRealmREorSA, 'RecruitmentContact': true}" target="_blank" :href="personalCandidate.URLs.employeeLinkedInUrl" v-if="personalCandidate.URLs.employeeLinkedInUrl.length >0">
                                            <span>
                                                <img class="img-fluid iconImg" alt="rounded" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/linkedin-icon-black.png') : require('@/assets/img_singlepageprofile/linkedin-icon.png')" onerror="this.style.display='none'" />
                                            </span>
                                            <span style="margin-left: 3.0em;">
                                                <label class="textline"> LinkedIn Professional Profile</label>
                                            </span>
                                        </a>
                                    </div>
                                    <hr style="border-width: 2px; margin-top: 1.8em; margin-bottom: 1.8em;" />


                                    <div class="GetInTouchQRSection" v-if="personalCandidate.qrCodeImage.length >0">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 ScanSaveContactText">
                                                Scan to save contact
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12">
                                                <img class="img-fluid QRCodes" alt="rounded" v-bind:src="personalCandidate.qrCodeImage" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="downloadShareSection">
                                        <div class="buttonWrapper">
                                            <a class="textShareButton" @click="downloadVCFCard()" target="_blank" :style="'background-color :' + personalCandidate.CssMedia.BackgroundColorButtons  + '; color: ' + personalCandidate.CssMedia.FontColorButtons">
                                                <span>
                                                    <img class="img-fluid iconImgDownloadShare" alt="rounded" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ personalCandidate.CssMedia.DownloadIconColorCss + '.png')" />
                                                    <span class="textShareButtonContent"> Save <!--Contact to Device--> </span>
                                                </span>
                                            </a>
                                            <a class="textShareButton" style="margin-left:2em;" @click="toggleShareOption()" :style="'background-color:' + personalCandidate.CssMedia.BackgroundColorButtons + '; color: ' + personalCandidate.CssMedia.FontColorButtons">
                                                <span>
                                                    <img class="img-fluid iconImgDownloadShare" alt="rounded" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ personalCandidate.CssMedia.ShareIconColorCss + '.png')" />
                                                    <span class="textShareButtonContent"> Share <!--Contact--> </span>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="commonContainer">
                                <div :class="['specialisationSection', {'specialisationSection-black': isRealmREorSA}]" v-if="personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation != undefined && personalCandidate.EmployeeInformation.specialisations.length >0">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <h4 class="SpecialisationTitle">Specialisations</h4>
                                    </div>
                                    <div>
                                        <div v-for="item in personalCandidate.EmployeeInformation.specialisations" :key="item" class="SpecialisationsText">
                                            {{ item.industry }}
                                            <ul v-if="item.roles != undefined && item.roles.length >0" class="SubSpecialisationsText">
                                                <li v-for="(role) in item.roles" :key="role">
                                                    {{ role }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="commonContainer">
                                <div :class="['thoughtLeadershipSection', {'thoughtLeadershipSection-black': isRealmREorSA}]" v-if="personalCandidate != null && personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation.userProfileLinks.length >0">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <h4 class="ThoughtLeadershipTitle">Thought Leadership</h4>
                                    </div>
                                    <div>
                                        <div v-for=" item in personalCandidate.EmployeeInformation.userProfileLinks" :key="item" class="thoughLeaderShipItem">
                                            <img class="img-fluid iconImgLeadership" alt="rounded" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/subpoint-icon-black.png') : require('@/assets/img_singlepageprofile/subpoint-icon-white.png')" />

                                            <a :class="{'ThoughLeaderShipContent': !isRealmREorSA, 'ThoughLeaderShipContent-black': isRealmREorSA}" target="_blank" :href="item.linkUrl" >
                                                {{ item.linkTitle }}
                                            </a>
                                        </div>

                                    </div>

                                    <hr style="border-width: 2px; margin-top: 1.8em; margin-bottom: 3.5em;" />

                                    <div class="ViewFullProfileContainer" v-if="personalCandidate != null && personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation.webProfileUrl != undefined && personalCandidate.EmployeeInformation.webProfileUrl.length >0">
                                        <a :href="personalCandidate.EmployeeInformation.webProfileUrl" target="_blank" :class="{'viewFullProfileLink': !isRealmREorSA, 'viewFullProfileLink-black': isRealmREorSA}">
                                            <label>View Full Profile </label>
                                            <span class="flexGrow"></span>
                                            <!--<span class="rsaquoSymbol">&rsaquo;</span>-->
                                            <span :class="{'rsaquoSymbol': !isRealmREorSA, 'rsaquoSymbol-black': isRealmREorSA}">&rsaquo;</span>

                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="license" v-if="personalCandidate.CompanyInformation != null && personalCandidate.CompanyInformation.employmentAgencyNo.length >0">
                        EA LICENSE : {{personalCandidate.CompanyInformation.employmentAgencyNo}}
                    </div>
                    <div class="license" v-if="personalCandidate.CompanyInformation != null && personalCandidate.CompanyInformation.companyRegistrationNo.length >0">
                        CO REG NUMBER  : {{personalCandidate.CompanyInformation.companyRegistrationNo}}
                    </div>

                    <div class="followUSContent">
                        <span class="FollowUSTitle">Follow Us</span>

                        <div class="row followUsIconsRow">
                            <div>
                                <div class="followUsIconsContainer">
                                    <div class="followUsIcon" v-if="personalCandidate.URLs.companyUrl.length>0">
                                        <a class="followusicon" target="_blank" :href="personalCandidate.URLs.companyUrl">
                                            <img class="img-fluid iconImgBottom" alt="icon" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/webfollowus-black.png') : require('@/assets/img_singlepageprofile/webfollowus.png')" onerror="this.style.display='none'" />
                                        </a>
                                        <a class="followusicon" target="_blank" :href="personalCandidate.URLs.companyLinkedInUrl">
                                            <img class="img-fluid iconImgBottom" alt="icon" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/linkedin-icon-black.png') : require('@/assets/img_singlepageprofile/linkedin-icon.png')" onerror="this.style.display='none'" />
                                        </a>
                                        <a class="followusicon" target="_blank" :href="personalCandidate.URLs.companyInstagramUrl">
                                            <img class="img-fluid iconImgBottom" alt="icon" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/instagram-icon-black.png') : require('@/assets/img_singlepageprofile/instagram-icon-white.png')" onerror="this.style.display='none'" />
                                        </a>
                                        <a class="followusicon" target="_blank" :href="personalCandidate.URLs.companyFacebookUrl">
                                            <img class="img-fluid iconImgBottom" alt="icon" :src="isRealmREorSA ? require('@/assets/img_singlepageprofile/facebookfollowus-black.png') : require('@/assets/img_singlepageprofile/facebookfollowus.png')" onerror="this.style.display='none'" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span class="footerSection">© 2024 {{this.brand}} - a brand of HRnetGroup</span>
                    <br /> <br />

                </div>
            </div>
                
        </div>
        <div class="col-lg-3 col-sm-1 col-md-2 col-xs-1 col-1"></div>
    </div>
    <!--<div class="view-label" style="color: blue;">
        <p>Current View: {{ currentView }}</p>
    </div>-->
</template>


<script>
        import axios from "axios"
        import "../components/custom.scss"
        
        //import { ShareLine } from 'vue-share-social'
        //import { useHead } from "@vueuse/head"
        //import copy from "copy-to-clipboard"

        // setup() => beforeCreate => created => beforemount => mounted => beforeupdate => updated --> beforeUnMount -> unmounted ?
        //data() => is where all the necessary dat ais binded
        //watcher() => to target and tell the vue what to do to the targeted object(s)
        //computed() => is behaved a function to bind certain data in data() to bind into UI.

        /*
        created:first time open a page/component
        mounted: every time a page/component opened bcos averytime you open it it mounts
        method: call when you wan to do sth, like call an action/mutation or do something other than dealing with store/state
        computed: you can use returned value to bind sth in template html, some changing value that changes according to some computations. If it wont change then just bind it to a date/state
        */
        export default {
            name : 'SinglePageProfile',
            //components : {
              //ShareLine
            //},
            /*setup() {
              useHead({
                title : document.title,
                meta : [
                  { name: "description", content : "Supply Planning&amp;nbsp; Ensure information architecture supporting the Supply Chain Planning, Master Data and Analytics processes is robust and integrated across the global network. Accountability of Inventory management of consigned materials:&amp;nbsp; Planning for the right stock levels of mainly API and Bulk supporting the manufacturing and packaging of company products Reconciliation of consigned materials on a periodic basis Assuring full SOX compliance for inventory management and accounts payable" },
                  { name: "author", content: "HRnetOne Singapore" },
                  { name : "keywords", content : "Namecard, HRnetOne, PeopleSearch, Consultants, Hiring Job, Headhunting"},
                  { property: "og:site_name", content: "HRnetOne - "+ document.title },
                  { property: "og:title", content: document.title },
                  { property: "og:type", content: "website" },
                  { property: "og:description", content  : "Supply Planning&amp;nbsp; Ensure information architecture supporting the Supply Chain Planning, Master Data and Analytics processes is robust and integrated across the global network. Accountability of Inventory management of consigned materials:&amp;nbsp; Planning for the right stock levels of mainly API and Bulk supporting the manufacturing and packaging of company products Reconciliation of consigned materials on a periodic basis Assuring full SOX compliance for inventory management and accounts payable"  },
                  {
                    property: "og:url",
                    content: document.location.origin
                  },
                  { name: "twitter:card", content: "testing twitter summary" },
                  { name: "twitter:creator", content: "testing twitter creator" },
                  { name: "twitter:site", content: "testing twitter site" }
                ]
              })
            }*/
            data() {
                return {         
                    employeeData    : null,
                    companyData     : null,
                    idEmployee      : "",
                    realm           : "",
                    brand           : "",
                    token           : "",                  
                    qrCodeImage     : "",
                    imageConsultant : "",
                    hasProfileImage : "",
                    vcfDetails      : "",
                    windowWidth     : window.innerWidth,
                    axiosObject     : axios.create({
                                   // baseURL : "http://localhost:50380/", 
                                    baseURL : "https://api.info.hrnetgroup.com",
                                    headers : {
                                        "Content-Type" : "application/json",
                                        "Access-Control-Allow-Headers" : "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
                                        "Access-Control-Allow-Credentials" : "true"
                        }
                    })
                }
            },
            created() {
                this.idEmployee = this.$route.params.id;
                if (this.idEmployee.length <= 0)
                {
                    this.$router.push('/notfound');
                }

                switch(document.location.origin.toLowerCase()) 
                {
                    case "https://about.hrnetone.com": 
                        this.realm = "hrn";
                        this.brand = "HRnetOne";
                        break;
                    case "https://about.hrnetrimbun.com":
                        this.realm = "hrn";
                        this.brand = "HRnetRimbun";
                        break;
                    case "https://about.peoplesearch.jobs":
                        this.realm = "pps";
                        this.brand = "PeopleSearch";
                        break;
                    case "https://about.peoplefirst.jobs":
                        this.realm = "pps";
                        this.brand = "PeopleFirst";
                        break;
                    case "https://about.recruitfirst.co":
                        this.realm = "rf";
                        this.brand = "RecruitFirst";
                        break;
                    case "https://about.octomate.us":
                        this.realm = "oss";
                        this.brand = "Octomate";
                        break;
                    case "https://about.recruitexpress.com.sg": 
                        this.realm = "res"; 
                        this.brand = "RecruitExpress";
                        break;
                    case "https://about.searchasia.com.sg": 
                        this.realm = "sa"; 
                        this.brand = "SearchAsia";
                        break;
                    case "https://about.leaps.sg":
                        this.realm = "vent";  
                        this.brand = "Leaps by HRnet";
                        break;
                    case "https://about.crew.sg":
                        this.realm = "vent";
                        this.brand = "CREW by HRnet";
                        break;
                    case "https://about.recruit-legal.com":   
                        this.realm = "rel"; 
                        this.brand = "Recruit Legal";
                        break;   
                    case "https://about.hrnetgroup.com":
                       this.realm = "hrn";
                       this.brand = "HRnetGroup";
                       break;  
                    default : 
                       this.realm = "hrn";
                       this.brand = "HRnetGroup";
                        break;
                }                         
            },  
            mounted() {
                this.getToken();
                window.addEventListener('resize', this.updateWidth);
            }, 
            unmounted() {
                window.removeEventListener('resize', this.updateWidth);
            },

        methods: {
                updateWidth() {
                    this.windowWidth = window.innerWidth;
                },
                getAuthHeader() {
                    return {
                        "Authorization" : "Bearer "+ this.token
                    }
                },
                async getToken() {                   
                    var response =  await this.axiosObject.post("/api/token", 
                    { 
                        username : "string", password : "string"
                    }).catch(error => { console.log(error) });
                    
                    if (response != undefined && response.data != undefined)
                    {
                        this.token = response.data;
                    }      
                    this.getDataProfileDetails();
                    this.downloadQRCode();
                    this.getProfileImage();
                },
                async getDataProfileDetails() {
                    await this.axiosObject.get("api/Profile/Get/"+ this.realm +"/"+ this.idEmployee, {
                        headers : this.getAuthHeader()
                    })
                    .then(x=> setTimeout(this.mappingData(x), 3000)).catch(error => { console.log(error); });
                },
                async getCompanyDetails(companyCode) {
                    setTimeout(() => {}, 20000);
                    await this.axiosObject.get("api/Profile/GetCompany/"+ this.realm +"/"+ companyCode, {
                        headers : this.getAuthHeader()}
                    ).then(x=>  {
                        if (x.data != undefined != null && x.data.statusCode == 1 && x.data.result != null && x.data.result != undefined)
                        {
                            this.companyData = x.data.result;
                        }
                    }).catch(error => { console.log(error); });
                },
                async downloadQRCode() {
                    //setTimeout(() => {}, 20000);
                    await this.axiosObject.get("api/Profile/GetVcardQRImage/"+this.realm+ "/"+ this.idEmployee, { responseType : 'arraybuffer', headers : this.getAuthHeader() })
                                  .then(response => this.qrCodeImage = "data:image/jpeg;base64, " + require("buffer").Buffer.from(response.data, "binary").toString("base64"))
                                  .catch(error => { console.log(error); });
                },
                async downloadVCFCard() {   
                    //setTimeout(() => {}, 20000);                
                    var response = await this.axiosObject.get("api/Profile/GetVCard/"+this.realm+ "/"+ this.idEmployee, {  headers : this.getAuthHeader() })
                                  .catch(error => { console.log(error); });
                    if (response != undefined && response.data != undefined && response.data.statusCode == 1)
                    {
                        /*
                        let bl = null;
                        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                        if (/windows phone|Windows/i.test(userAgent) || /android/i.test(userAgent)) {
                          bl = new Blob([response.data.result], { type : 'text/vcard;charset=utf-8' });
                        } // iOS detection from: http://stackoverflow.com/a/9039885/177710
                        else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                          bl = new Blob([response.data.result], { type : 'text/x-vcard;charset=utf-8' });
                        }*/
                        let bl = new Blob([response.data.result], { type : 'text/vcard;charset=utf-8' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(bl);
                        link.download = this.employeeData.displayName + ".vcf";
                        link._target = '_blank';
                        link.click();
                    }
                },
                async getProfileImage() {
                    await this.axiosObject.get("api/Profile/GetProfileImage/"+this.realm+ "/"+ this.idEmployee, { responseType : 'arraybuffer', headers : this.getAuthHeader() })
                    .then(response => this.imageConsultant = "data:image/jpeg;base64, " + require("buffer").Buffer.from(response.data, "binary").toString("base64"))
                                .catch(error => { console.log(error); });
                
                    const profileImageResponse = await this.axiosObject.get("api/Profile/GetProfileImage/" + this.realm + "/" + this.idEmployee, { responseType: 'arraybuffer', headers: this.getAuthHeader() });
                    const ogImageUrl           = document.querySelector('meta[property="og:image"]').getAttribute('content');
                    this.hasProfileImage       = profileImageResponse.headers.hasContentLength();
                    
                    if(profileImageResponse.headers.hasContentLength() == false)  //has no profile photo
                    {
                        if (ogImageUrl.includes("Logo") === false) 
                        {
                            this.imageConsultant = ogImageUrl;
                        }
                    }  
                },
                toggleShareOption() {
                    navigator.share({
                        title : this.employeeData.displayName,
                        text : "Meet "+this.employeeData.displayName+ " from "+ this.employeeData.companyName,
                        url : document.location.href
                    });
                },
                mappingData(x) {
                    if (x.data != undefined != null && x.data.statusCode == 1 && x.data.result != null && x.data.result != undefined)
                    {
                        this.employeeData = x.data.result;
                        //this.employeeData.selfIntroVideoUrl = "abc";
                        //this.employeeData.specialisations = ["INFORMATION TECHNOLOGY", "MECHANICAL ENGINEER", "IT SECURITY"];
                        //this.employeeData.webProfileUrl = [{linkUrl : "abc", linkTitle : "abc"}];
                        //this.employeeData.userProfileLinks = [{linkUrl : "abc", linkTitle : "abc"}];

                        //computation for specialisations
                        if (this.employeeData.specialisations.length >0)
                        {
                          var finalizedResult = [];
                          for (let index = 0; index < this.employeeData.specialisations.length; index++)
                          {
                             if (index == 0) 
                             {
                                var SplitWord = this.employeeData.specialisations[index].split(":");
                                var CheckingWord = SplitWord[1] != undefined ? [SplitWord[1]] : [];
                                finalizedResult.push({ industry : SplitWord[0], roles :  CheckingWord});
                             } 
                             else 
                             {
                                var splittedWord = this.employeeData.specialisations[index].split(":");
                                if (finalizedResult.findIndex(x=> x.industry == splittedWord[0]) > -1) {
                                      finalizedResult.find(x=> x.industry == splittedWord[0]).roles.push(splittedWord[1]);
                                } else {
                                    var checkingRoles = splittedWord[1] != undefined ? [splittedWord[1]] : [];
                                    finalizedResult.push({industry : splittedWord[0], roles : checkingRoles });
                                }
                              }
                          }
                          if (finalizedResult.length >0) {
                              this.employeeData.specialisations = finalizedResult;
                          }
                        }

                        document.title = this.employeeData.displayName;
                        this.$gtag.pageview("Namecard for Consultant : " + this.idEmployee + ", Realm : " + this.realm, "Singapore", document.location.href);
                        this.getCompanyDetails(this.employeeData.companyCode);
                    } else {
                        this.$router.push('/notfound');
                    }

                    let allLinks = [];
                    
                    if (this.employeeData.userProfileLinks != null && this.employeeData.userProfileLinks.length >0) {
                        allLinks.push(...this.employeeData.userProfileLinks);
                    }

                    if (this.employeeData.entityArticleLinks != null && this.employeeData.entityArticleLinks.length >0) {
                        allLinks.push(...this.employeeData.entityArticleLinks);
                    }

                    this.employeeData.userProfileLinks = allLinks;
                }             
            },
            computed : {

                currentView() {
                    const width = this.windowWidth;
                    let viewType = '';
                    if (width < 768) {
                        viewType = 'sm'; // Small view (example threshold: < 768px)
                    } else if (width < 1024) {
                        viewType = 'md'; // Medium view (example threshold: < 1024px)
                    } else {
                        viewType = 'lg'; // Large view (example threshold: >= 1024px)
                    }
                    return `${viewType} (${width}px)`;
                },

                isRealmREorSA() {                 
                    return this.brand === "RecruitExpress" || this.brand === "SearchAsia" || this.brand === "Recruit Legal";                
                },

                personalCandidate() {
                    var BackGroundColor           = "";
                    var BackgroundColorIntroVideo = "";
                    var BackgroundColorButton     = "";
                    var FontColorButton           = "";
                    var DownloadIconColor         = "";
                    var ShareIconColor            = "";
                    var listOfUrls                = {};

                    var CompanyUrl                = "";
                    var CompanyLinkedInUrl        = "";
                    var CompanyInstagramUrl       = "";
                    var CompanyFacebookUrl        = "";
                    var EmployeeLinkedUrl         = "";
                    var EmployeeWhatsappUrl       = "";
                    var EmployeeLineUrl           = "";
                    var CompanyLogo               = "";
                    var PlayButton                = "";

                    if (this.employeeData != undefined && this.employeeData.realm != undefined) 
                    {          
                        if (this.brand === "Leaps by HRnet") {
                            BackGroundColor           = "linear-gradient(130deg, #78C3D4 0%, #1A7D93 100%)";
                            BackgroundColorIntroVideo = "linear-gradient(to right, #ffc000, #faac0f, #f39322, #ed7d31 )";
                            BackgroundColorButton     = "#FFFFFF";
                            FontColorButton           = "#1A7D93";
                            CompanyLogo               = "leaps_logo_white.png";
                            PlayButton                = "black_Play_Button.png";
                            DownloadIconColor         = "download-icon-leap";
                            ShareIconColor            = "share-icon-leap";
                        }
                        else if (this.brand === "CREW by HRnet") {
                            BackGroundColor           = "linear-gradient(130deg, #3780D4 0%, #3961BF 100%)";
                            BackgroundColorIntroVideo = "linear-gradient(to right, #ffc000, #faac0f, #f39322, #ed7d31 )";
                            BackgroundColorButton     = "#FFFFFF";
                            FontColorButton           = "#3961BF";
                            CompanyLogo               = "crew_logo_white.png";
                            PlayButton                = "black_Play_Button.png";
                            DownloadIconColor         = "download-icon-crew";
                            ShareIconColor            = "share-icon-crew";
                        } else {
                            switch (this.employeeData.realm.toLowerCase()) {
                                case "hrn":
                                    BackGroundColor           = 'url(' + require('@/assets/img_singlepageprofile/HRN_background.png') + ') ';  //"linear-gradient(45deg,#ff7e56,#fec554)";                         
                                    BackgroundColorIntroVideo = "linear-gradient(to right, #ffc000, #faac0f, #f39322, #ed7d31 )";
                                    BackgroundColorButton     = "#FFFFFF";//"#ed7d31";
                                    FontColorButton           = "#FF9200";
                                    CompanyLogo               = document.location.origin.toLowerCase() == "https://about.hrnetgroup.com" ? "hrnetgroup.png" : this.employeeData.companyCode.toLowerCase() == "hri" ? "hrn-4.png" : "hri-4.png";
                                    PlayButton                = "Play_Icon_Orange.png";
                                    DownloadIconColor         = "download-icon-hrn";
                                    ShareIconColor            = "share-icon-hrn";
                                    break;
                                case "rf":
                                    BackGroundColor           = this.employeeData.companyCode.toLowerCase() == "ossg" ? 'url(' + require('@/assets/img_singlepageprofile/oct_background.jpg') + ')' : 'url(' + require('@/assets/img_singlepageprofile/5mb_RF_background_vertical.png') + ')';
                                    BackgroundColorIntroVideo = this.employeeData.companyCode.toLowerCase() == "ossg" ? "linear-gradient(to right, #006C70, #009EA4, #00BDC3)" : "linear-gradient(to right, #eeb66d, #e2957f, #cd6485, #c23487)";
                                    BackgroundColorButton     = this.employeeData.companyCode.toLowerCase() == "ossg" ? "#0C879C" : "#FFFFFF";
                                    FontColorButton           = "#6F42CC";
                                    CompanyLogo               = this.employeeData.companyCode.toLowerCase() == "ossg" ? "octomate.png" : "rf-3.png";
                                    PlayButton                = this.employeeData.companyCode.toLowerCase() == "ossg" ? "Play_Icon_Green.png" : "Play_Icon_purple.png";
                                    DownloadIconColor         = "download-icon-rf";
                                    ShareIconColor            = "share-icon-rf";
                                    break;
                                case "pps":
                                    BackGroundColor           = 'url(' + require('@/assets/img_singlepageprofile/5mb_PPS_background_vertical.png') + ')';
                                    BackgroundColorIntroVideo = "linear-gradient(to right, #223c6a, #426fc0, #30579a)";
                                    FontColorButton           = "#004DA6";
                                    BackgroundColorButton     = "#FFFFFF";
                                    CompanyLogo               = this.employeeData.companyCode.toLowerCase() == "pfmy" ? "ppmy.png" : "pps.png";
                                    PlayButton                = "Play_Icon_Blue.png";
                                    DownloadIconColor         = "download-icon-pps";
                                    ShareIconColor            = "share-icon-pps";
                                    break;
                                case "sa":
                                    BackGroundColor           = 'url(' + require('@/assets/img_singlepageprofile/SA_background.png') + ')';
                                    BackgroundColorIntroVideo = "linear-gradient(to right, #ffc000, #faac0f, #f39322, #ed7d31 )";
                                    BackgroundColorButton     = "#000000";
                                    FontColorButton           = "#FFFFFF";
                                    CompanyLogo               = "SearchAsia_Black.png";
                                    PlayButton                = "black_Play_Button.png";
                                    DownloadIconColor         = "download-icon-white";
                                    ShareIconColor            = "share-icon-white";
                                    break;
                                case "res":
                                    BackGroundColor           = 'url(' + require('@/assets/img_singlepageprofile/RE_background.png') + ')';
                                    BackgroundColorIntroVideo = "linear-gradient(to right, #ffc000, #faac0f, #f39322, #ed7d31 )";
                                    BackgroundColorButton     = "#000000";
                                    FontColorButton           = "#FFFFFF";
                                    CompanyLogo               = "RecruitExpressLogo_Black.png";
                                    PlayButton                = "black_Play_Button.png";
                                    DownloadIconColor         = "download-icon-white";
                                    ShareIconColor            = "share-icon-white";
                                    break;
                                case "oss":
                                    BackGroundColor           = this.employeeData.companyCode.toLowerCase() == "ossg" ? 'url(' + require('@/assets/img_singlepageprofile/oct_background.jpg') + ')' : 'url(' + require('@/assets/img_singlepageprofile/5mb_RF_background_vertical.png') + ')';
                                    BackgroundColorIntroVideo = this.employeeData.companyCode.toLowerCase() == "ossg" ? "linear-gradient(to right, #006C70, #009EA4, #00BDC3)" : "linear-gradient(to right, #eeb66d, #e2957f, #cd6485, #c23487)";
                                    BackgroundColorButton     = this.employeeData.companyCode.toLowerCase() == "ossg" ? "#0C879C" : "#FFFFFF";
                                    FontColorButton           = "#FFFFFF";
                                    CompanyLogo               = this.employeeData.companyCode.toLowerCase() == "ossg" ? "octomate.png" : "rf-3.png";
                                    PlayButton                = this.employeeData.companyCode.toLowerCase() == "ossg" ? "Play_Icon_Green.png" : "Play_Icon_purple.png";
                                    DownloadIconColor         = "download-icon-white";
                                    ShareIconColor            = "share-icon-white";
                                    break;
                                /*case "vent":  
                                    BackGroundColor           = "linear-gradient(130deg, #78C3D4 0%, #1A7D93 100%)";
                                    BackgroundColorIntroVideo = "linear-gradient(to right, #ffc000, #faac0f, #f39322, #ed7d31 )";
                                    BackgroundColorButton     = "#FFFFFF";
                                    FontColorButton           = "#1A7D93";
                                    CompanyLogo               = "leaps_logo_white.png";
                                    PlayButton                = "black_Play_Button.png";
                                    DownloadIconColor         = "download-icon-leap";
                                    ShareIconColor            = "share-icon-leap";
                                    break;*/
                                case "rel":
                                    BackGroundColor           = 'url(' + require('@/assets/img_singlepageprofile/SA_background.png') + ')';
                                    BackgroundColorIntroVideo = "linear-gradient(to right, #ffc000, #faac0f, #f39322, #ed7d31 )";
                                    BackgroundColorButton     = "#000000";
                                    FontColorButton           = "#FFFFFF";
                                    CompanyLogo               = "recruit-legal-logo-black.png";
                                    PlayButton                = "black_Play_Button.png";
                                    DownloadIconColor         = "download-icon-white";
                                    ShareIconColor            = "share-icon-white";
                                    break;
                            }
                        }

                        EmployeeWhatsappUrl = (this.employeeData.whatsAppUrl.length > 0 ? this.employeeData.whatsAppUrl : "");
                        EmployeeLinkedUrl = (this.employeeData.linkedInUrl.length > 0 ? this.employeeData.linkedInUrl : "");
                        EmployeeLineUrl   = (this.employeeData.lineUrl != undefined && this.employeeData.lineUrl.length >0 ? this.employeeData.lineUrl : "");
                    }

                    if (this.companyData != null) 
                    {
                        CompanyUrl = ((this.companyData != null && this.companyData.websiteUrl != undefined && this.companyData.websiteUrl.length >0) ? this.companyData.websiteUrl : "");
                        CompanyLinkedInUrl = ((this.companyData != null && this.companyData.linkedInUrl != undefined && this.companyData.linkedInUrl.length >0) ? this.companyData.linkedInUrl : "");
                        CompanyInstagramUrl = ((this.companyData != null && this.companyData.instagramUrl != undefined && this.companyData.instagramUrl.length >0) ? this.companyData.instagramUrl : "");
                        CompanyFacebookUrl = ((this.companyData != null && this.companyData.facebookUrl != undefined && this.companyData.facebookUrl.length >0) ? this.companyData.facebookUrl : "");
                    }

                    listOfUrls = 
                    {  
                        companyInstagramUrl : CompanyInstagramUrl,
                        companyFacebookUrl  : CompanyFacebookUrl,
                        companyLinkedInUrl  : CompanyLinkedInUrl,
                        companyUrl          : CompanyUrl,
                        employeeWhatsappUrl  : EmployeeWhatsappUrl, 
                        employeeLinkedInUrl  :  EmployeeLinkedUrl,
                        employeeLineUrl      : EmployeeLineUrl
                    }

                    return {
                        EmployeeInformation : this.employeeData,
                        CompanyInformation: this.companyData,         
                        CssMedia : {
                            BackgroundColorCss : BackGroundColor,
                            BackgroundColorIntroVideoLink : BackgroundColorIntroVideo,
                            BackgroundColorButtons: BackgroundColorButton,
                            FontColorButtons: FontColorButton,
                            BackgroundCompanyLogo : CompanyLogo,
                            BackgroundPlayButton: PlayButton,
                            DownloadIconColorCss: DownloadIconColor,
                            ShareIconColorCss: ShareIconColor
                        },
                        Realm : this.realm,
                        URLs : listOfUrls,
                        webShareAPiSupported : navigator.share,
                        qrCodeImage : this.qrCodeImage,
                        imageConsultant : this.imageConsultant,
                        hasProfileImage : this.hasProfileImage
                    }
                }
            }
        }
</script>
