<template>
  <div class="container-fluid">
    <router-view></router-view>
  </div>
  <!--<SinglePageProfilePrototype :messageHeader = "'Hello There-Ricardo'" />-->
</template>

 
<script>
//import SinglePageProfilePrototype from './components/singlepageprofile-prototype.vue'
//import "bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";
export default {
  name: 'App',
  components: {
    //SinglePageProfilePrototype
  }
}
</script>